// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faqs-js": () => import("/opt/build/repo/src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-french-js": () => import("/opt/build/repo/src/pages/french.js" /* webpackChunkName: "component---src-pages-french-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locator-js": () => import("/opt/build/repo/src/pages/locator.js" /* webpackChunkName: "component---src-pages-locator-js" */),
  "component---src-pages-press-js": () => import("/opt/build/repo/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-reviews-js": () => import("/opt/build/repo/src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-science-js": () => import("/opt/build/repo/src/pages/science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

